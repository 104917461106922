<script>
import {mapGetters} from "vuex";

export default ({
  name: "PlayerEmotion",
  props: {
    player: {
      type: Object,
      required: true,
    }
  },
  computed: {
    ...mapGetters('game', ['isGameOver', 'currentRoomState']),
    ...mapGetters('emotion', ['emotions']),
    isPlayerDisconnected() {
      return (this.player && this.player.isDisconnected || false);
    },
    isPlayerLeft() {
      return (this.player && this.player.isSitout || false);
    },
    isPlayerWaiting() {
      const playState = this.player.playState || -1;
      const reserveState = this.player.reserveState || -1;

      if (playState === this.playState.FOLD && reserveState === this.playState.WAIT) {
        return true;
      }

      return reserveState >= this.playState.WAIT_FROM_ENTER && reserveState <= this.playState.WAIT_FROM_DEALDER;
    },
    isPlayerWaitingFromSmall() {
      return this.player.reserveState === this.playState.WAIT_FROM_SMALL;
    },
    isPlayerWaitingFromBig() {
      return this.player.reserveState === this.playState.WAIT_FROM_BIG;
    },
    emotion() {
      if (!this.player) {
        return false;
      }

      const hasEmotion = this.emotions[this.player.sn] || false;
      return !hasEmotion ? null : hasEmotion.emotion;
    },
    isDisplayEmoji(){
      return this.emotion && this.emotion.type === 'emoji';
    },
    isDisplayMessage(){
      return this.emotion && this.emotion.type === 'message';
    },
  },
})
</script>

<template>
  <transition appear name="left-slide" mode="out-in">
    <div class="area_bubble" v-if="isDisplayMessage">
      <div v-if="emotion" class="speech-bubble" :class="[emotion.type]">
        {{ emotion.content }}
      </div>
    </div>
    <div class="area_emoji" v-if="isDisplayEmoji">{{ emotion.content }}</div>
  </transition>
</template>

<style scoped lang="scss">
.left-slide-enter-active {
  position: absolute;
  transition: .3s ease-in-out;
}

.left-slide-enter, .left-slide-appear /* .fade-leave-active in <2.1.8 */
{
  position: absolute;
  opacity: 0;
  visibility: hidden;
  transform-origin: right center;
  transform: translateX(10px)
}

.speech-bubble.emoji {
  line-height: inherit;
  font-size: 2rem;
  height: auto;
}
</style>